export const missions = {
  missionDetailedReport: [
    {
      id: 'gameGoals',
      label: 'Game Goals Text',
      component: 'Input',
      placeholder: 'Game Goals Text'
    },
    {
      id: 'cognitiveSkillsGoals',
      label: 'Cognitive Skills Goals Text',
      component: 'Input',
      placeholder: 'Cognitive Skills Goals Text'
    },
    {
      id: 'behavioralChangesChild',
      label: 'Behavioral Changes to look for text (child)',
      component: 'Input',
      placeholder: 'Behavioral Changes to look for text (child)'
    },
    {
      id: 'behavioralChangesAdult',
      label: 'Behavioral Changes to look for text (adult)',
      component: 'Input',
      placeholder: 'Behavioral Changes to look for text (adult)'
    },
    {
      id: 'failedRunner',
      label: 'Failed Runner',
      component: 'Input',
      placeholder: 'Failed Runner'
    },
    {
      id: 'passedRunner1Star',
      label: 'Passed Runner with 1 star',
      component: 'Input',
      placeholder: 'Passed Runner with 1 star'
    },
    {
      id: 'passedRunner2Star',
      label: 'Passed Runner with 2 star',
      component: 'Input',
      placeholder: 'Passed Runner with 2 star'
    },
    {
      id: 'passedRunner3Star',
      label: 'Passed Runner with 3 star',
      component: 'Input',
      placeholder: 'Passed Runner with 3 star'
    },
    {
      id: 'whatsNextAfterRunner',
      label: "What's next section after runner",
      component: 'Title',
      placeholder: "What's next popup after runner"
    },
    {
      id: 'whatsNextFailedRunner',
      label: 'Failed the runner',
      component: 'Input',
      placeholder: 'Failed the runner'
    },
    {
      id: 'failedRunnerNoAttempt',
      label: 'Passed runner but did not attempt',
      component: 'Input',
      placeholder: 'Passed runner but did not attempt'
    },
    {
      id: 'whatsNextAfterAttempt',
      label: "What's next section after",
      component: 'Title',
      placeholder: "What's next popup after"
    },
    {
      id: 'failedAttempt',
      label: 'Failed the',
      component: 'Input',
      placeholder: 'Failed the'
    },
    {
      id: 'passedAttempt',
      label: 'Passed the',
      component: 'Input',
      placeholder: 'Passed the'
    }
  ],
  summaryReport: [
    {
      id: 'missionNumberFocus',
      label: 'Mission # Focus',
      component: 'Input',
      placeholder: 'Mission # Focus'
    },
    {
      id: 'focusDefinitionPopup',
      label:
        'Focus definition definition pops up when you hover over focus as well as the red/orange line',
      component: 'Input',
      placeholder:
        'Focus definition definition pops up when you hover over focus as well as the red/orange line'
    },
    {
      id: 'impulsControlDefinition',
      label:
        'Impulse control definition definition pops up when you hover over impulse control as well as the blue line)',
      component: 'Input',
      placeholder:
        'Impulse control definition definition pops up when you hover over impulse control as well as the blue line)'
    }
  ]
};

export const cognitiveSkillsScoresDefinitions = [
  {
    id: 'focusedAttention',
    label: 'Focused Attention',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'sustainedAttention',
    label: 'Sustained Attention',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'cognitiveInhibition',
    label: 'Cognitive Inhibition',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'behavioralInhibition',
    label: 'Behavioral Inhibition',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'selectiveAttention',
    label: 'Selective Attention',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'alternatingAttention',
    label: 'Alternating Attention',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'dividedAttention',
    label: 'Divided Attention',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'interferenceControl',
    label: 'Interference Control',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'motivationalInhibition',
    label: 'Motivational Inhibiton',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'noveltyInhibition',
    label: 'Novelty Inhibition',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'delayOfGratification',
    label: 'Delay of Gratification',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'innerVoice',
    label: 'Inner Voice',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  },
  {
    id: 'selfRegulation',
    label: 'Self-Regulation ',
    field: [
      {
        id: 'definition',
        label: 'Definition',
        component: 'Input',
        placeholder: 'Definition'
      },
      {
        id: 'cognitiveSkillDefinition',
        label: 'Cognitive Skills Definition',
        component: 'Input',
        placeholder: 'Cognitive Skills Definition'
      }
    ]
  }
];

export const missionsDefaultValues = () => {
  const defaultValues = {};

  missions.missionDetailedReport
    .filter((key) => key.component !== 'Title')
    .forEach((key) => {
      defaultValues[key.id] = '';
    });

  missions.summaryReport.forEach((key) => {
    defaultValues[key.id] = '';
  });

  return defaultValues;
};

export const getMissionsQuery = () => {
  //   return setTimeout(() => {}, 5000);
  const result = Array.from({ length: 15 }).map((item, index) => {
    const defaultValues = {};
    missions.missionDetailedReport.forEach((key) => {
      defaultValues[key.id] = '';
    });

    missions.summaryReport.forEach((key) => {
      defaultValues[key.id] = '';
    });
    return defaultValues;
  });
  return result;
};

export const defaultCognitiveSkillsValues = () => {
  const defaultValues = {};
  cognitiveSkillsScoresDefinitions.forEach((key) => {
    defaultValues[key.id] = {
      definition: '',
      cognitiveSkillDefinition: ''
    };
  });
  return defaultValues;
};

export {};
