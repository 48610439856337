import { FormLabel, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
// import Textarea from '@mui/joy/Textarea';
import { Controller, useForm } from 'react-hook-form';
import FormHelperText from '@mui/joy/FormHelperText';
import TextField from '@mui/material/TextField';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';
import { missions, missionsDefaultValues } from 'src/utils/missionsFormHelper';
import { useMutation, useQuery } from 'react-query';
import { getMissions } from 'src/services';
import Button from '@mui/material/Button';
import api from 'src/services/api';

const Missions = () => {
  const [missionNumber, setMission] = useState<string>('1');
  const handleChange = (event: SelectChangeEvent) => {
    const mission = (missionDetails as any).find((mission: any) => {
      return mission.missionId === event.target.value;
    });

    if (mission) {
      reset(mission);
    } else {
      reset(missionsDefaultValues());
    }
    setMission(event.target.value as any);
  };

  const { data, isFetching } = getMissions();

  const {
    handleSubmit,
    register,
    watch,
    control,
    reset,
    formState: { errors }
  } = useForm<{
    [key: string]: string;
  }>({});

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  const mutation = useMutation({
    mutationFn: (dialog: any) => {
      return api.put(`/mission-dialog/${missionNumber}`, dialog);
    },
    onSuccess: (data) => {
      refetchMissionDetails();
    }
  });

  const { data: missionDetails, refetch: refetchMissionDetails } = useQuery(
    'missionDetails',
    () => api.get(`/mission-dialog`)
  );

  useEffect(() => {
    if (!missionDetails) {
      return;
    }
    (missionDetails as any).forEach((mission: any) => {
      if (mission.missionId === missionNumber) {
        reset(mission);
      }
    });
  }, [missionDetails]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  if (isFetching) return <div>Loading...</div>;

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={2}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Mission</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={missionNumber}
            label="Mission"
            onChange={handleChange}
          >
            {Array.from({ length: 15 }).map((mission: number, index) => (
              <MenuItem value={(index + 1).toString()} key={index + 1}>
                {`Mission ${index + 1}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={2}>
          <Typography variant="h1" component="h2">
            Mission Details Reports
          </Typography>
          {missions.missionDetailedReport.map((mission, index) => {
            return mission.component === 'Input' ? (
              <Controller
                key={mission.id}
                name={mission.id}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState
                }) => (
                  <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <FormLabel>
                      {[
                        'failedRunnerNoAttempt',
                        'failedAttempt',
                        'passedAttempt'
                      ].includes(mission.id)
                        ? mission.label +
                          ' ' +
                          (+missionNumber === 1 ? 'Vigilock' : 'Fog Analysis')
                        : mission.label}
                    </FormLabel>
                    <TextareaAutosize
                      {...register(mission.id, {
                        required: true
                      })}
                      placeholder={
                        [
                          'failedRunnerNoAttempt',
                          'failedAttempt',
                          'passedAttempt'
                        ].includes(mission.id)
                          ? mission.placeholder +
                            ' ' +
                            (+missionNumber === 1 ? 'Vigilock' : 'Fog Analysis')
                          : mission.placeholder
                      }
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      style={{ width: '100%' }}
                      minRows={4}
                    />
                    {errors[mission.id] &&
                      errors[mission.id].type === 'required' && (
                        <span role="alert" style={{ color: 'red' }}>
                          This field is required
                        </span>
                      )}
                  </Box>
                )}
              />
            ) : (
              <h1>
                {mission.id === 'whatsNextAfterAttempt'
                  ? +missionNumber === 1
                    ? mission.label + ' ' + 'Vigilock'
                    : mission.label + ' ' + `Fog Analysis`
                  : mission.label}
              </h1>
            );
          })}
          <Typography variant="h1" component="h2">
            Summary Reports
          </Typography>
          {missions.summaryReport.map((mission) => (
            <Controller
              key={mission.id}
              name={mission.id}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState
              }) => (
                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                  <FormLabel>{mission.label}</FormLabel>
                  <TextareaAutosize
                    {...register(mission.id, {
                      required: true
                    })}
                    placeholder={mission.placeholder}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    style={{ width: '100%' }}
                    minRows={4}
                  />
                  {errors[mission.id] &&
                    errors[mission.id].type === 'required' && (
                      <span role="alert" style={{ color: 'red' }}>
                        This field is required
                      </span>
                    )}
                </Box>
              )}
            />
          ))}

          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};
const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75'
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025'
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === 'dark' ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default Missions;
