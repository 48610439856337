import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import {
  useTheme,
  Heading,
  Authenticator,
  withAuthenticator
} from '@aws-amplify/ui-react';
import ThemeProvider from './theme/ThemeProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalizationProvider as DateProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});
function App() {
  const content = useRoutes(router);

  return (
    <Authenticator.Provider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />

                {content}
              </DateProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Authenticator.Provider>
  );
}

export default withAuthenticator(App, {
  components: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          backgroundColor={'white'}
          padding={`${tokens.space.small} 0 0 ${tokens.space.small}`}
          level={3}
          marginLeft={1}
          marginRight={1}
          textAlign={'center'}
        >
          <img src={'/static/images/logo/logo.png'} alt="logo" width="50" />
        </Heading>
      );
    }
  },

  formFields: {
    // dont show username field

    // signUp: {
    //   username: {
    //     label: 'Email',
    //     placeholder: 'Enter your email',
    //     required: true,
    //     order: 1
    //   },
    //   given_name: {
    //     label: 'First Name',
    //     placeholder: 'Enter your first name',
    //     required: true,
    //     order: 2
    //   },
    //   family_name: {
    //     label: 'Last Name',
    //     placeholder: 'Enter your last name',
    //     required: true,
    //     order: 3
    //   }
    // },
    signIn: {
      username: {
        label: 'Email',
        placeholder: 'Enter your email',
        required: true,
        order: 1
      }
    }
  },
  hideSignUp: true
});
