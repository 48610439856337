import { FormLabel, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material/Select';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';
import {
  cognitiveSkillsScoresDefinitions,
  defaultCognitiveSkillsValues
} from 'src/utils/missionsFormHelper';
import { useMutation, useQuery } from 'react-query';
import { getMissions } from 'src/services';
import Button from '@mui/material/Button';
import api from 'src/services/api';

const CognitiveSkills = () => {
  const handleChange = (event: SelectChangeEvent) => {
    reset(data[event.target.value]);
  };

  const { data, isFetching } = getMissions();

  console.log(defaultCognitiveSkillsValues());

  const { handleSubmit, register, watch, control, reset, setValue, getValues } =
    useForm<any>({
      defaultValues: defaultCognitiveSkillsValues()
    });

  const onSubmit = (data: any) => {
    mutation.mutate(data, {
      onSuccess: () => {
        refetch();
      }
    });
  };

  const { data: cognitiveSkillsScoresDefinitionsData, refetch } = useQuery(
    'cognitiveSkillsScoresDefinitions',
    () => api.get('/cognitive-skill-definition')
  );

  useEffect(() => {
    reset(cognitiveSkillsScoresDefinitionsData);
  }, [cognitiveSkillsScoresDefinitionsData]);

  const mutation = useMutation({
    mutationFn: (dialog: any) => {
      return api.post(`/cognitive-skill-definition`, dialog);
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  if (isFetching) return <div>Loading...</div>;

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={2}>
          <Typography variant="h1" component="h2">
            Cognitive Skills Scores Definitions
          </Typography>

          {cognitiveSkillsScoresDefinitions.map((mission) => (
            <>
              <Typography variant="h1" component="h2">
                {mission.label}
              </Typography>
              <Controller
                key={mission.id + mission.field[0].id}
                name={mission.id}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState
                }) => (
                  <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <FormLabel>{mission.field[0].label}</FormLabel>
                    <TextareaAutosize
                      onChange={(e) => {
                        // onChange(e);
                        setValue(mission.id, {
                          ...getValues()[mission.id],
                          definition: e.target.value
                        });
                      }}
                      value={value.definition}
                      style={{ width: '100%' }}
                      minRows={4}
                    />
                  </Box>
                )}
              />
              <Controller
                key={mission.id + mission.field[1].id}
                name={mission.id}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState
                }) => (
                  <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <FormLabel>{mission.field[1].label}</FormLabel>
                    <TextareaAutosize
                      onChange={(e) => {
                        setValue(mission.id, {
                          ...getValues()[mission.id],
                          cognitiveSkillDefinition: e.target.value
                        });
                      }}
                      value={value.cognitiveSkillDefinition}
                      style={{ width: '100%' }}
                      minRows={4}
                    />
                  </Box>
                )}
              />
            </>
          ))}

          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};
const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75'
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025'
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === 'dark' ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default CognitiveSkills;
