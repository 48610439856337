import axios from 'axios';
import { Auth } from 'aws-amplify';

const api = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API_URL
});

const portalApi = axios.create({
  baseURL: process.env.REACT_APP_PORTAL_API_URL
});

[api, portalApi].forEach((item) => {
  item.interceptors.request.use(async (config) => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    config.headers.Authorization = `Bearer ${token}`;
    console.log(token)
    return Promise.resolve(config);
  });

  item.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error)
  );
});

export { portalApi };

export default api;
