import API, { portalApi } from './api';
import { useQuery } from 'react-query';
import {
  GetParentQuery,
  GetParentResponse,
  GetHeadsetsResponse,
  Progress,
  Parent,
  Child,
  AttentionResponse,
  TransferenceRes,
  FogAnalysisRes,
  GetAttemptsResponse,
  GetChildStateResponse,
  ImpulseControlData,
  Headset,
  PaginatedPerformanceSession,
  DashboardStats
} from 'src/types';
import usePagination from 'src/utils/hooks/usePagination';
import { getMissionsQuery } from 'src/utils/missionsFormHelper';

function getParamsFromPagination(ctx: any) {
  const pagination = {
    page: ctx.pageParam?.page,
    size: ctx.pageParam?.size || ctx.meta.size
  };

  return {
    ...ctx.meta?.filters,
    ...pagination
  };
}

export const getAttempts = async (
  pagination: any
): Promise<GetAttemptsResponse> => {
  console.log({ pagination });
  return API.get('/attempts', {
    params: getParamsFromPagination(pagination)
  });
};

export const getTransferenceData = async ({
  username,
  sessionId
}: {
  username: string;
  sessionId: string;
}): Promise<TransferenceRes> => {
  return API.get(`/children/${username}/transferences/${sessionId}`);
};

export const useGetTransferenceData = ({
  username,
  sessionId
}: {
  username: string;
  sessionId: string;
}) => {
  return useQuery(
    ['transference', username, sessionId],
    () => getTransferenceData({ username, sessionId }),
    {
      enabled: !!username && !!sessionId
    }
  );
};

// export const useGetAttempts = (pagination: Pagination) => {
//   return useQuery(
//     ['attempts', pagination.page, pagination.size],
//     () => getAttempts(pagination),
//     {
//       keepPreviousData: true
//     }
//   );
// };

export const getHeadsets = async (
  pagination: any
): Promise<GetHeadsetsResponse> => {
  console.log({ pagination });
  return API.get('/headsets', {
    params: {
      page: pagination.pageParam?.page,
      size: pagination.pageParam?.size || pagination.meta.size
    }
  });
};

export const useGetAttemptsInfinite = (pageSize: number) => {
  return usePagination(getAttempts, 'attempts', pageSize);
};

export const useGetHeadsetsInfinite = (pageSize: number) => {
  return usePagination(getHeadsets, 'headsets', pageSize);
};

export const getParents = async (
  params: GetParentQuery
): Promise<GetParentResponse> => {
  // const searchParams = generateSearchParams(params);
  const searchParams = getParamsFromPagination(params);
  return API.get(`/parents`, {
    params: searchParams
  });
};

export const useGetParents = (params: GetParentQuery) => {
  return useQuery(
    ['parents', params.partialName, params.page, params.size],
    () => getParents(params),
    {
      keepPreviousData: true
    }
  );
};

export const useGetParentsInfinite = (pageSize: number) => {
  return usePagination(getParents, 'parents', pageSize);
};

export const getChildState = async (
  username: string
): Promise<GetChildStateResponse> => {
  return API.get(`/children/${username}/state`);
};

export const useGetChildState = (username: string) => {
  return useQuery(['child-state', username], () => getChildState(username), {
    keepPreviousData: true
  });
};

export const getParent = async (id: string): Promise<Parent> => {
  return API.get(`/parents/${id}`);
};

export const useGetParent = (id: string) => {
  return useQuery(['parent', id], () => getParent(id));
};

export const getChild = async (username: string): Promise<Child> => {
  return API.get(`/children/${username}`);
};

export const useGetChild = (username: string) => {
  return useQuery(['child', username], () => getChild(username));
};

export const updateChild = async (
  username: string,
  data: any
): Promise<Child> => {
  return API.put(`/children/${username}`, data);
};

export const useGetCohorts = (username: string) => {
  return useQuery(['coachCohorts', username], () => getCoachCohorts(username));
};

export const useGetCoach = (username: string) => {
  return useQuery(['coach', username], () => getCoach(username));
};

export const updateCoach = async (
  username: string,
  data: any
): Promise<Child> => {
  return API.put(`/coaches/${username}`, data);
};

export const uploadCsv = async (
  cohortId: string,
  data: any,
  username: string
): Promise<any> => {
  return API.post(`/coaches/${username}/cohort/${cohortId}/import`, data);
};

export const createCoach = async (
  data: any
): Promise<void> => {
  return API.post(`/coaches`, data);
};

export const getCoach = async (username: string): Promise<Child> => {
  return API.get(`/coaches/${username}`);
};

export const getCoachCohorts = async (username: string): Promise<any> => {
  return API.get(`/coaches/${username}/cohorts`);
};

export const updateParent = async (id: string, data: any): Promise<Parent> => {
  return API.put(`/parents/${id}`, data);
};

export const getHeadset = async (id: string): Promise<Headset> => {
  return API.get(`/headsets/${id}`);
};

export const useGetHeadset = (id: string) => {
  return useQuery(['headset', id], () => getHeadset(id), {
    enabled: !!id
  });
};

export const updateHeadset = async (
  id: string,
  data: any
): Promise<Headset> => {
  return API.put(`/headsets/${id}`, data);
};

export const createHeadset = async (data: any): Promise<Headset> => {
  return API.post(`/headsets`, data);
};

export const getImpulseControl = async (
  username: string
): Promise<ImpulseControlData[]> => {
  return portalApi.get(`/children/${username}/impulse-control`);
};

export const useGetImpulseControl = (username: string) => {
  return useQuery<ImpulseControlData[]>(
    ['impulse-control', username],
    () => getImpulseControl(username),
    {
      enabled: !!username
    }
  );
};

export const assignHeadset = async (headsetId: string, username: string) => {
  return API.post(`/headsets/${headsetId}/assignment`, { username });
};

export const getProgress = async (username: string): Promise<Progress> => {
  return API.get(`/children/${username}/progress-report`);
};

export const useGetProgress = (username: string) => {
  return useQuery(['progress', username], () => getProgress(username), {
    enabled: !!username
  });
};

export const getPerformance = async (
  username: string,
  pagination: any
): Promise<PaginatedPerformanceSession> => {
  const params = getParamsFromPagination(pagination);
  return API.get(`/children/${username}/perf-report`, {
    params
  });
};

export const useGetPerformance = (
  username: string,
  pageSize: number,
  filters?: any
) => {
  return usePagination(
    (pagination) => getPerformance(username, pagination),
    'performance',
    pageSize,
    filters
  );
};

export const getDashboardStats = async (
  period?: string,
  type?: string
): Promise<DashboardStats> => {
  return API.get(`/dashboard?scale=${period}${type}`);
};

export const useGetDashboardStats = (period, type) => {
  return useQuery(['dashboard-stats', period, type], () =>
    getDashboardStats(period, type)
  );
};

export const getAttention = async (
  username: string,
  sessionId: string
): Promise<AttentionResponse> => {
  return portalApi.get(`/children/${username}/sessions/${sessionId}/attention`);
};

export const useGetAttention = ({
  username,
  sessionId
}: {
  username: string;
  sessionId: string;
}) => {
  return useQuery(
    ['attention', username, sessionId],
    () => getAttention(username, sessionId),
    {
      enabled: !!username && !!sessionId
    }
  );
};

export const getFogAnalysis = async (
  username: string,
  sessionId: string
): Promise<FogAnalysisRes> => {
  return portalApi.get(
    `/children/${username}/sessions/${sessionId}/fog-analysis`
  );
};

export const useGetFogAnalysis = ({
  username,
  sessionId
}: {
  username: string;
  sessionId: string;
}) => {
  return useQuery<FogAnalysisRes>(
    ['fog-analysis', username, sessionId],
    () => getFogAnalysis(username, sessionId),
    {
      enabled: !!username && !!sessionId
    }
  );
};

export const getChildren = async (pagination: any): Promise<Child[]> => {
  const params = getParamsFromPagination(pagination);
  // const { filters, ...rest } = params;
  // console.log({ filters });
  return API.get(`/children`, {
    params
  });
};

export const getCohorts = async (): Promise<any> => {
  return API.get(`/cohorts/all`);
};

export const getImports = async (pagination: any): Promise<any> => {
  const params = getParamsFromPagination(pagination);
  return API.get(`/imports`, {
    params
  });
};

export const getCoaches = async (pagination: any): Promise<any> => {
  const params = getParamsFromPagination(pagination);
  return API.get(`/coaches`, {
    params
  });
};

export const useGetChildren = () => {
  return useQuery(['children'], getChildren);
};

export const useGetAllCohorts = () => {
  return useQuery(['cohorts'], getCohorts);
};

export const useGetChildrenInfinite = (pageSize: number, filters?: any) => {
  return usePagination(getChildren, 'children', pageSize, filters);
};

export const getMissions = () => {
  return useQuery(['missions'], {
    queryFn: () => getMissionsQuery(),
    staleTime: Infinity
  });
};

export const useGetImportJobs = (pageSize: number, filters?: any) => {
  return usePagination(getImports, 'children', pageSize, filters);
};

export const useGetCoaches = (pageSize: number, filters?: any) => {
  return usePagination(getCoaches, 'children', pageSize, filters);
};
